export default {
  computed: {
    isValid: function () {
      // Single validation
      if (typeof this.validation === 'object' && this.validation.condition === true) {
        return false
      }
      // Multiple validation
      if (this.validations.length > 0) {
        let isValid = true

        this.validations.forEach((item) => {
          if (item.condition === true) isValid = false
        })
        return isValid
      }
      return true
    },
    hasValidator () {
      return (typeof this.validation !== 'undefined' || this.validations.length > 0)
    }
  }
}
