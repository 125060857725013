<template>
  <div class="relative">
    <div class="relative">
      <label
        v-if="hasDefaultSlot"
        :class="[{'required' : required}, labelClass]"
        class="label uppercase text-black text-h6 tracking-md leading-thick whitespace-no-wrap"
      >
        <slot />
      </label>
      <div class="relative">
        <input class="text-dark py-sm px-5 focus:outline-none focus:bg-grey-11 border border-solid border-dark text-average leading-sm tracking-xs w-full"
               :class="[{'has-error pr-8' : (isValid === false)}, inputClass]"
               :type="type === 'password' ? passType : type"
               :id="id"
               :name="name"
               :autocomplete="autocomplete"
               :value="value"
               :autofocus="autofocus"
               :ref="name"
               :maxlength="maxLength"
               :placeholder="placeholder"
               @change="changeEvent"
               @input="inputEvent"
               @focus="$emit('focus')"
               @blur="$emit('blur')"
               @keyup.enter="keyupEnter"
               @keyup="keyup"
               :readonly="readonly"
               :required="required"
               :disabled="disabled"
               :accept="accept"
        >
        <svg class="icon icon-md" v-if="isValid === false && hasValidator === true"><use xlink:href="/assets/icons.svg#error" /></svg>
      </div>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages'
import Validation from 'theme/mixins/forms/Validation'

export default {
  name: 'BaseInput',
  mixins: [Validation],
  components: {
    ValidationMessages
  },
  data () {
    return {
      passType: 'password',
      iconActive: false,
      icon: 'visibility',
      isDirty: false
    }
  },
  computed: {
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    maxLength: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    accept: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    inputClass: {
      type: [String, Array],
      required: false,
      default: ''
    },
    labelClass: {
      type: [String, Object],
      required: false,
      default: () => {}
    },
    validation: {
      type: Object,
      required: false,
      default: () => { }
    },
    validations: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    togglePassType () {
      if (this.passType === 'password') {
        this.passType = 'text'
        this.icon = 'visibility_off'
      } else {
        this.passType = 'password'
        this.icon = 'visibility'
      }
    },
    // setFocus sets focus on a field which has a value of 'ref' tag equal to fieldName
    setFocus (fieldName) {
      if (this.name === fieldName) {
        this.$refs[this.name].focus()
      }
    },
    keyup ($event) {
      this.$emit('keyup', $event)
      this.isDirty = true
    },
    keyupEnter ($event) {
      this.$emit('keyup.enter', $event.target.value)
      this.isDirty = true
    },
    inputEvent ($event) {
      this.$emit('input', $event.target.value)
      this.isDirty = true
    },
    changeEvent ($event) {
      this.$emit('change', $event)
      this.isDirty = true
    }
  },
  created () {
    if (this.type === 'password') {
      this.iconActive = true
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-error: #e81212;

  .label {
    &.required {
      &:before {
        content: '\002A\0020';
        font-size: 15px;
        position: relative;
        top: 3px;
      }
    }
  }
  .icon {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .has-error {
    border-bottom-width: 2px;
    border-bottom-color: $color-error;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-grey-13;
  }

  input::placeholder,
  textarea::placeholder {
    @apply text-grey-13;
  }
</style>
