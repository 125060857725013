<template>
  <div class="select-wrapper relative">
    <label
      v-if="hasDefaultSlot"
      class="label uppercase text-black text-h6 tracking-md leading-thick"
    >
      <slot />
    </label>
    <div class="relative">
      <select
        class="text-dark bg-white py-sm px-5 focus:outline-none border border-solid border-dark text-average leading-sm tracking-xs w-full"
        :class="[{'has-error' : (isValid === false)},inputClass]"
        :name="name"
        :autocomplete="autocomplete"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @change="$emit('input', $event.target.value)"
        @input="$emit('input', $event.target.value)"
        ref="select"
      >
        <option value="" disabled selected="selected" v-if="placeholder">
          {{ placeholder }}
        </option>
        <option
          v-for="(option, key) in options"
          :key="key"
          :value="option.value"
          v-bind="{selected: option.value === selected}"
        >
          {{ option.label }}
        </option>
        <slot name="additionalOption"/>
      </select>
      <svg class="icon pointer-events-none" :class="[ caretSize ]"><use xlink:href="/assets/icons.svg#caret-down" /></svg>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages'
import Validation from 'theme/mixins/forms/Validation'

export default {
  name: 'BaseSelect',
  mixins: [Validation],
  components: {
    ValidationMessages
  },
  computed: {
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },
  watch: {
    options: function (newValue, oldValue) {
      // console.log(this.$refs.select.$el)
    }
  },
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    caretSize: {
      type: String,
      required: false,
      default: 'icon-md'
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    selected: {
      type: [String, Number],
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    validations: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-error: #e81212;

  .select-wrapper {
    select {
      border-radius: 0;
      padding-right: 53px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -webkit-border-radius: 0;
      cursor: pointer;
    }
  }
  .has-error {
    border-bottom-width: 2px;
    border-bottom-color: $color-error;
  }
  .icon {
    @apply absolute top-50%;
    transform: translateY(-50%);
    right: 20px;
  }
</style>
