<template>
  <component
    class="inline-block slow-color-change whitespace-no-wrap outline-none rounded-0 hover:bg-primary2 border-0 text-white text-left text-h5 uppercase tracking-sm relative"
    :class="[`bg-${color}`, { 'pointer-events-none opacity-50': disabled }, (small ? 'py-1 pl-5 pr-10' : 'py-4 pr-15 pl-10')]"
    :type="link ? '' : type" :is="link ? 'router-link' : 'button'" :to="localizedRoute(link)" :disabled="disabled"
  >
    <span>
      <slot>
        Button
      </slot>
    </span>
    <i class="ss-gizmo absolute mr-6 -mt-1.5 right-0 top-50% leading-none" :class="(loading) ? 'hidden' : iconClass" />
    <loader :color="`text-` + loadingColor" v-if="loading" />
  </component>
</template>
<script>
import focusClean from 'theme/components/theme/directives/focusClean'
import Loader from 'theme/components/theme/Loader'

export default {
  name: 'ButtonWithIcon',
  directives: {
    focusClean
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    },
    iconClass: {
      type: String,
      required: false,
      default: 'ss-right'
    },
    link: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingColor: {
      type: String,
      required: false,
      default: 'white'
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Loader
  }
}
</script>
