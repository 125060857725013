<template>
  <div id="subscription-form" class="container mx-auto lg:px-17">
    <div class="w-full md:w-2/3 m-auto">
      <h1 class="mt-6 mb-4 text-lg uppercase text-center">
        NEW PRODUCT LAUNCH<br> GRATIS PROGRAM
      </h1>
      <div v-if="submitted" class="text-center">
        Thank you for your submission.
      </div>
      <div class="copy" v-if="!submitted">
        <div class="subtitle text-md mb-4 text-center">
          SUBSCRIBE TO RECEIVE ALL NEW PRODUCT LAUNCHES<br>
          (Excluding Holiday)
        </div>
        <div>
          <h2 class="text-mid text-center">BENEFITS OF THE PROGRAM</h2>
          <ul class="list-disc ml-10 py-6">
            <li>
              <strong>Option 1</strong> - Stylist Gratis, 6 pcs of each new product
            </li>
            <li>
              <strong>Option 2</strong> - Stylist Gratis, 3 pcs of each new product
            </li>
            <li>
              Complimentary Stylist Station + Backbar for options 1 and 2
            </li>
            <li>
              Receive 500 Reward Points when signing up (will be issued
              each calendar year with renewal of program)
            </li>
            <li>
              Free Priority Shipping on all new launch orders
            </li>
          </ul>
        </div>
        </div>

      <div v-if="!canUpdate" class="bg-brown-6 border-l-4 border-primary2 text-primary p-4" role="alert">
        <p class="font-bold">Already Subscribed</p>
        <p>Looks like you're already subscribed for the year.</p>
      </div>

      <form @submit.prevent="submit" v-if="!submitted && canUpdate">
        <!-- Brand, Subscription Choice, Rep Name, Rep Signature -->
        <base-select
          class="mb-2"
          name="brand"
          :options="getBrandOptions"
          :placeholder="$t('Select a Brand')"
          autocomplete="brand"
          v-model="formData.brand"
          :validations="[
              {
                condition: $v.formData.brand.$error && !$v.formData.brand.required,
                text: validateMessage('required', { attr: 'Brand'})
              }
            ]"
        >
          {{ $t('Brand') }} *
        </base-select>
        <base-select
          class="mb-2"
          name="location"
          :options="getLocationOptions"
          :placeholder="$t('Select a Location')"
          autocomplete="location"
          v-model="formData.customer_id"
          :validations="[
              {
                condition: $v.formData.customer_id.$error && !$v.formData.customer_id.required,
                text: validateMessage('required', { attr: 'Location'})
              }
            ]"
        >
          {{ $t('Location') }} *
        </base-select>
        <base-select
          class="mb-2"
          name="subscription"
          :options="getSubscriptionOptions"
          :placeholder="$t('Select an Option')"
          autocomplete="subscription"
          v-model="formData.subscription"
          :validations="[
              {
                condition: $v.formData.subscription.$error && !$v.formData.subscription.required,
                text: validateMessage('required', { attr: 'Subscription'})
              }
            ]"
        >
        Option *
        </base-select>
        <base-input
          class="mb-2"
          :placeholder="'Representative Full Name'"
          autocomplete="representative_name"
          name="representative_name"
          type="text"
          @blur="$v.formData.representative_name.$touch()"
          v-model="formData.representative_name"
          :validations="[
              {
                condition: $v.formData.representative_name.$error && !$v.formData.representative_name.required,
                text: validateMessage('required', { attr: 'Representative Full Name'})
              },
              {
                condition: $v.formData.representative_name.$error && !$v.formData.representative_name.minLength,
                text: validateMessage('minLength', { attr: 'Representative Full Name', min: 5 })
              }
      ]"
        >
          Authorized Salon Representative *
        </base-input>

        <button-with-icon
          @click.native="submit"
          :color="`primary2`"
          class="popup-button flex-grow mt-2 pr-5 pl-10 w-full">
                 <span>Submit</span>
        </button-with-icon>
      </form>
      <div class="copy" v-if="!submitted">
        <div class="legal py-4 text-md">
          <p><strong>Dollar value of each new product launch order may vary depending on number of SKUs launched
            and cost of goods.</strong></p>

          <p>The Program shall be automatically renewed for one (1) year periods unless, at least thirty (30) days prior to the end of the current
            Gratis Program term, the salon gives R+Co written notice of it's intent to opt out of the Gratis Program. In the event that the
            salon opts out of the Gratis program, the salon will be ineligible for, re-enrollment in the Gratis Program until the next
            calendar year following the notice electing to opt out of the Gratis Program.</p>

          <p>Customer may change their option choice with written notice to their Account Consultant at least fourteen (14) days before next
            launch date.</p>

          <p>Upon entering into this agreement R•Co will ship the desired package on the first day of availability and charge the default credit
            card on file.</p>

          <p>Salons can opt in at any time during the year.</p>

          <p>Return of products purchased under the Gratis Program must be accompanied by all complimentary products received with that
            bundle to be eligible for a credit. Standard restocking fees and charges will apply. The return of products received under the Gratis Program will result in termination of the Gratis Program and the salon will be ineligible for re-enrollment in the
            Gratis Program until the next calendar year.</p>
        </div>
        <div>
          For more information, please contact your Brand Manager or Account Consultant.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength} from 'vuelidate/lib/validators'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import {httpClient} from 'theme/helpers/httpClient'
import validateMessage from 'theme/helpers/valdiateMessage'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import { mapState } from 'vuex'

export default {
  name: 'SubscriptionForm',
  components: {ButtonWithIcon, BaseSelect, BaseInput},
  mixins: [validateMessage],
  mounted () {
  },
  data () {
    return {
      loaded: false,
      submitted: false,
      formData: {
        brand: '',
        subscription: '',
        representative_name: '',
        customer_id: ''
      },
      serverData: {},
      // used in filtering subscription options
      mapper: {
        // toggle to enable or disable brands
        1: [4, 5] // rco
        // 2: [11, 12] // bleu
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    canUpdate () {
      if (Object.keys(this.serverData).length) {
        // annually
        const today = new Date()
        const postedDate = new Date(this.serverData.created_at)
        return today.getFullYear() !== postedDate.getFullYear()
      }

      return true
    },
    getSubscriptionOptions () {
      return [
        // R+Co
        {
          label: 'Option 1 - Retail, 12 pcs of each new product',
          value: 4
        },
        {
          label: 'Option 2 - Retail, 6 pcs of each new product',
          value: 5
        },
        // R+Co Bleu
        {
          label: 'Option 1 - Retail, 12 pcs of each new product',
          value: 11
        },
        {
          label: 'Option 2 - Retail, 6 pcs of each new product',
          value: 12
        }
      ].filter(x => (this.mapper[this.formData.brand] || []).includes(x.value))
    },
    getBrandOptions () {
      return [
        {
          label: 'R+Co',
          value: 1,
          brand_id: 1
        }
        // {
        //   label: 'R+Co Bleu',
        //   value: 2,
        //   brand_id: 1
        // }
      ].filter(x => Object.keys(this.mapper).includes(String(x.brand_id)))
    },
    getLocationOptions () {
      let data = [];

      (((this.currentUser || {}).extension_attributes || {}).locations_data || []).forEach(x => {
        if (!this.formData.brand) return
        if (!this.mapper[x.brand_id]) return

        data.push({
          label: x.nick_name,
          value: x.location_id
        })
      })

      return data
    }
  },
  validations () {
    return {
      formData: {
        brand: {
          required
        },
        subscription: {
          required
        },
        representative_name: {
          required,
          minLength: minLength(2)
        },
        customer_id: {
          required
        }
      }
    }
  },
  methods: {
    brandMapper (id) {
      return this.getBrandOptions.find(x => x.value === id).brand_id
    },

    notifyUser (message, type = 'error') {
      this.$store.dispatch('notification/spawnNotification', {
        type: type,
        message: message,
        action1: { label: this.$t('OK') }
      })
    },
    edit () {
      this.serverData = null
    },
    getServerData () {
      httpClient.get(`https://data-bridge.luxbp.com/api/v1/lbpro-subscription/${this.customer_id}`).then((response) => {
        this.serverData = response.data
      }).catch(err => console.log(err))
    },
    submit () {
      this.$v.$touch()

      let formData = Object.assign({}, this.formData)

      if (this.$v.$invalid) {
        // Notification
        this.$v.$touch()
      } else {
        httpClient.post('https://data-bridge.luxbp.com/api/v1/lbpro-subscription', formData).then((response) => {
          // handle success
          if (response.data.status === true) {
            this.submitted = true
          }

          if (response.data.errors && response.data.errors.length) {
            (response.data.errors || []).forEach((err) => {
              this.notifyUser(err)
            })
          }
        }).catch((err) => {
          let message = typeof err.response !== 'undefined' ? err.response.data.message : err.message

          if (err.response.data.errors && err.response.data.errors.length) {
            (err.response.data.errors || []).forEach((err) => {
              this.notifyUser(err)
            })
          }

          console.warn('error', message, err.response)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 p {
   @apply pb-4;
 }

</style>
