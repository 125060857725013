const messages = {
  'required': ':attr is required',
  'minLength': ':attr must have at least :min letters.',
  'maxLength': ':attr must have at least :max letters.',
  'email': ':attr must be valid email.',
  'url': ':attr must be valid url.',
  'isPhone': ':attr must be valid phone number.',
  'sameAs': ':attr must be same as :field.'
}

function replacePlaceholders (str, attr) {
  let currentStr = str

  for (let k in attr) {
    currentStr = currentStr.replace(':' + k, attr[k])
  }

  return currentStr
}

export default {
  methods: {
    validateMessage: function (ruleKey, attr = {}) {
      if (typeof attr === 'object' && Object.keys(attr).length > 0) {
        return messages.hasOwnProperty(ruleKey) ? replacePlaceholders(messages[ruleKey], attr) : ''
      }
      return messages.hasOwnProperty(ruleKey) ? messages[ruleKey] : ''
    }
  }
}
